import React from "react"
import Band from "../../components/band"

import Layout from "../../components/layout"

const MessageDC = () => (
  <Layout isMi={true}>
    <div className="bg-white">
      <div className="cf-container band-sm pb-0 text-center">
        <h1 className="font-weight-semi">RECENT CYBERATTACK</h1>
        <h3 className="mb-0 mt-4">A message from our President & CEO, George Aloth</h3>
      </div>
    </div>
    <Band>
      <p>
        I understand that recent news of a cyberattack on Trusted Health Plans, Inc, (Trusted Health Plan Michigan and Harbor Health) is concerning for our enrollees and others with whom we did business. Unfortunately, cybersecurity attacks have become all too common. Maintaining the privacy and security of our enrollees personal information is one of our highest priorities.
      </p>
      <p>
        We’ve taken immediate steps to limit the impact of the attack and protect and secure our systems and the information of our enrollees and partners. We are offering the potentially affected individuals two years of free credit monitoring and identity theft protection services to ease concerns about the possible unauthorized use of personal information. I encourage you to take advantage of this offer if your information was impacted.
      </p>
      <p>
        We’re taking aggressive action on behalf of all those we serve to help address questions and ensure you are supported. Please take a moment to review the information in our FAQ for answers to your questions.
      </p>
      <p>
        Thank you.
      </p>
    </Band>
  </Layout>
)

export default MessageDC
